<template>
  <div class="text-editor-container">
    <h5 class="mb-2" v-if="!hide_title">Descripción</h5>
    <div class="rich-text-editor" ref="editor"></div>
    <!-- Input:
    <div class="rich-text-content" v-html="Text"></div>
    <div>{{ Text }}</div>
    output:
    <div class="rich-text-content" v-html="text"></div>
    <div>{{ text }}</div> -->
    <div class="row">
      <b-form-group
        v-if="show_last_modification == true"
        label-for="input-modification-date"
        label-cols="0"
        label-cols-sm="0"
        class="p-0 m-0 mt-2 ml-3"
      >
        <ModificationDate
          v-if="!isNaN(object_last_modification.id)"
          :Information="object_last_modification"
        ></ModificationDate>
      </b-form-group>
      <div class="col mt-1" style="text-align: right">
        <button
          class="btn btn-primary mr-0"
          :disabled="permit_blank ? false : text == ''"
          size="sm"
          @click="save"
        >
          Guardar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Quill from "quill";
import * as QuillTableUI from "quill-table-ui"; // Importa quill-table-ui
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export default {
  name: "NewRichTextEditor",
  components: {
    ModificationDate: () => import("@/components/reusable/ModificationDate"),
  },
  props: {
    Object: {
      type: Object,
    },
    Type: {
      type: Object,
    },
    Text: {
      type: String,
      default: "",
    },
    permit_blank: {
      type: Boolean,
      default: false,
    },
    hide_title: {
      type: Boolean,
      default: false,
    },
    show_last_modification: {
      type: Boolean,
      default: false,
    },
    object_last_modification: {
      type: Object,
    },
  },
  data() {
    return {
      text: this.Text,
    };
  },
  mounted() {
    let Icon = Quill.import("ui/icons");
    // Metodo para agregar todos los iconos custom
    this.addCustomIcons(Icon);
    if (!Quill.imports["modules/tableUI"]) {
      Quill.register("modules/tableUI", QuillTableUI.default); // Registra el módulo tableUI
    }
    this.quill = new Quill(this.$refs.editor, {
      theme: "snow",
      placeholder: "Escribe algo...",
      modules: {
        toolbar: {
          container: [
            [
              { header: [1, 2, 3, 4, false] },
              { size: ["small", false, "large", "huge"] },
            ],
            ["bold", "italic", "underline", "strike"],
            [
              { align: "" },
              { align: "center" },
              { align: "right" },
              { align: "justify" },
            ],
            ["blockquote", "code-block"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ color: [] }, { background: [] }],
            ["link", "image", "video"],
            // ["table"],
            [
              "insertTable",
              "addColumnLeft",
              "addColumnRight",
              "addRowBelow",
              "addRowAbove",
              "deleteRow",
              "deleteColumn",
              "deleteTable",
            ],
          ],
          handlers: {
            insertTable: this.insertTable,
            deleteTable: this.deleteTable,
            addColumnLeft: this.addColumnLeft,
            addColumnRight: this.addColumnRight,
            addRowBelow: this.addRowBelow,
            addRowAbove: this.addRowAbove,
            deleteRow: this.deleteRow,
            deleteColumn: this.deleteColumn,
          },
        },
        table: true,
        tableUI: false,
      },
    });
    if (this.Text) this.quill.clipboard.dangerouslyPasteHTML(this.Text);
    this.quill.on("text-change", this.handleTextChange);

    this.applyCounterReset();
    //Metodo para agregar todos los titulos de los botones del toolbar
    this.titleInButtonsOfToolbar();
  },
  methods: {
    handleTextChange() {
      this.text = this.quill.root.innerHTML; // Actualiza la variable `text` con el HTML actual del editor
      // En caso que venga vacio que se guarde sin las etiquetas html.
      if (this.$htmlToPlainText(this.text).trim() == "") this.text = "";
      this.applyCounterReset(); // Aplica el reinicio del contador después de cada cambio
    },
    applyCounterReset() {
      const olElements = this.quill.root.querySelectorAll("ol");
      olElements.forEach((ol) => {
        ol.style.counterReset = "list-0";
      });
    },
    insertTable() {
      // Inserta una tabla 3x3 al hacer clic en el botón personalizado
      const tableModule = this.quill.getModule("table");
      if (tableModule) {
        tableModule.insertTable(2, 2);
      }
    },
    deleteTable() {
      const tableModule = this.quill.getModule("table");
      if (tableModule) {
        const range = this.quill.getSelection();
        if (range) {
          const [cell] = this.quill.getLine(range.index);
          if (cell && cell.domNode.tagName === "TD") {
            const table = cell.domNode.closest("table");
            if (table) {
              table.remove();
            }
          }
        }
      }
    },
    addColumnLeft() {
      // Añade una columna a la derecha de la tabla seleccionada
      const tableModule = this.quill.getModule("table");
      if (tableModule) {
        tableModule.insertColumnLeft();
      }
    },
    addColumnRight() {
      // Añade una columna a la derecha de la tabla seleccionada
      const tableModule = this.quill.getModule("table");
      if (tableModule) {
        tableModule.insertColumnRight();
      }
    },
    addRowBelow() {
      // Añade una fila debajo de la fila seleccionada en la tabla
      const tableModule = this.quill.getModule("table");
      if (tableModule) {
        tableModule.insertRowBelow();
      }
    },
    addRowAbove() {
      // Añade una fila debajo de la fila seleccionada en la tabla
      const tableModule = this.quill.getModule("table");
      if (tableModule) {
        tableModule.insertRowAbove();
      }
    },
    deleteRow() {
      // Lógica para eliminar una fila
      const tableModule = this.quill.getModule("table");
      if (tableModule) {
        tableModule.deleteRow();
      }
    },
    deleteColumn() {
      // Lógica para eliminar una columna
      const tableModule = this.quill.getModule("table");
      if (tableModule) {
        tableModule.deleteColumn();
      }
    },
    save() {
      if (this.Object) {
        this.$emit("save", this.Object, this.text.trim());
        this.$emit("close");
      } else if (this.Type) {
        this.$emit("save", this.text.trim(), this.Type);
        this.$emit("close");
      } else {
        this.$emit("save", this.text.trim());
        this.$emit("close");
      }
    },
    titleInButtonsOfToolbar() {
      document
        .querySelector(".ql-insertTable")
        .setAttribute("title", "Insertar tabla 2x2");
      document
        .querySelector(".ql-deleteTable")
        .setAttribute("title", "Eliminar tabla");
      document
        .querySelector(".ql-addColumnLeft")
        .setAttribute("title", "Agregar columna a la izquierda");
      document
        .querySelector(".ql-addColumnRight")
        .setAttribute("title", "Agregar columna a la derecha");
      document
        .querySelector(".ql-addRowBelow")
        .setAttribute("title", "Agregar fila debajo");
      document
        .querySelector(".ql-addRowAbove")
        .setAttribute("title", "Agregar fila arriba");
      document
        .querySelector(".ql-deleteRow")
        .setAttribute("title", "Eliminar Fila");
      document
        .querySelector(".ql-deleteColumn")
        .setAttribute("title", "Eliminar Columna");
    },
    addCustomIcons(Icon) {
      // Icono para insertar una tabla 2x2
      Icon["insertTable"] =
        '<svg viewBox="0 0 14 14"><rect x="2" y="2" width="4" height="4" stroke="black" stroke-width="1" fill="none"></rect><rect x="8" y="2" width="4" height="4" stroke="black" stroke-width="1" fill="none"></rect><rect x="2" y="8" width="4" height="4" stroke="black" stroke-width="1" fill="none"></rect><rect x="8" y="8" width="4" height="4" stroke="black" stroke-width="1" fill="none"></rect></svg>';
      // Eliminar Tabla
      Icon["deleteTable"] = `
          <svg viewBox="0 0 24 24">
           <!-- Tabla -->
            <rect x="4" y="4" width="16" height="16" rx="2" ry="2" stroke-width="1.5" stroke="#000" fill="none"></rect>
            <line x1="8" y1="4" x2="8" y2="20" stroke-width="1.5" stroke="#000"></line>
            <line x1="16" y1="4" x2="16" y2="20" stroke-width="1.5" stroke="#000"></line>
            <line x1="4" y1="8" x2="20" y2="8" stroke-width="1.5" stroke="#000"></line>
            <line x1="4" y1="16" x2="20" y2="16" stroke-width="1.5" stroke="#000"></line>
            <!-- X en rojo -->
            <line x1="6" y1="6" x2="18" y2="18" stroke-width="2" stroke="#ff0000"></line>
           <line x1="18" y1="6" x2="6" y2="18" stroke-width="2" stroke="#ff0000"></line>
          </svg>
        `;
      // Icono para agregar columna a la izquierda
      Icon["addColumnLeft"] =
        '<svg viewBox="0 0 18 18"><rect x="6" y="3" width="1" height="12" class="ql-stroke"></rect><rect x="12" y="3" width="0" height="12" class="ql-stroke"></rect><line class="ql-stroke" x1="8" x2="11" y1="9" y2="9"></line><polyline class="ql-fill" points="5 6 8 9 5 12"></polyline></svg>';
      // Icono para agregar columna a la derecha
      Icon["addColumnRight"] =
        '<svg viewBox="0 0 18 18"><rect x="5" y="3" width="0" height="12" class="ql-stroke"></rect><rect x="11" y="3" width="1" height="12" class="ql-stroke"></rect><line class="ql-stroke" x1="7" x2="10" y1="9" y2="9"></line><polyline class="ql-fill" points="10 6 13 9 10 12"></polyline></svg>';
      // Icono para agregar fila debajo
      Icon["addRowBelow"] =
        '<svg viewBox="0 0 18 18"><rect x="3" y="5" width="12" height="0" class="ql-stroke"></rect><rect x="3" y="11" width="12" height="1" class="ql-stroke"></rect><line class="ql-stroke" x1="9" x2="9" y1="7" y2="10"></line><polyline class="ql-fill" points="6 10 9 13 12 10"></polyline></svg>';
      // Icono para agregar fila arriba
      Icon["addRowAbove"] =
        '<svg viewBox="0 0 18 18"><rect x="3" y="5" width="12" height="1" class="ql-stroke"></rect><rect x="3" y="11" width="12" height="0" class="ql-stroke"></rect><line class="ql-stroke" x1="9" x2="9" y1="10" y2="7"></line><polyline class="ql-fill" points="6 7 9 4 12 7"></polyline></svg>';
      // Icono de eliminar Fila
      Icon["deleteRow"] = `<svg viewBox="0 0 24 24">
         <rect x="4" y="10" width="16" height="4" stroke-width="1.5" stroke="#000" fill="none"></rect>
         <line x1="6" y1="8" x2="18" y2="20" stroke-width="2" stroke="#ff0000"></line>
         <line x1="18" y1="8" x2="6" y2="20" stroke-width="2" stroke="#ff0000"></line>
        </svg>`;
      // Icono para eliminar columna
      Icon["deleteColumn"] = `
        <svg viewBox="0 0 24 24">
         <rect x="10" y="4" width="4" height="16" stroke-width="1.5" stroke="#000" fill="none"></rect>
         <line x1="8" y1="6" x2="16" y2="18" stroke-width="2" stroke="#ff0000"></line>
         <line x1="16" y1="6" x2="8" y2="18" stroke-width="2" stroke="#ff0000"></line>
       </svg>`;
    },
  },
};
</script>
<style scoped src="@/utils/rich_text_editor.css">
</style>
<style scoped >
.ql-table-toggle {
  position: absolute; /* Posiciona el botón relativo al contenedor */
  z-index: 10; /* Asegura que el botón esté encima de otros elementos */
}

.rich-text-editor {
  position: relative; /* Necesario para que la posición absoluta funcione */
}
.rich-text-editor >>> .ql-editor {
  min-height: 200px;
  font-size: 16px;
}

.ql-editor >>> ol {
  counter-reset: list-0; /* Reinicia el contador para cada lista ordenada */
}

.ql-editor >>> ol li {
  list-style-type: none;
  counter-increment: list-0;
}

.ql-editor >>> ol li::before {
  content: counter(list-0, decimal) ". ";
}

.ql-editor >>> ol li.ql-indent-1 {
  counter-reset: list-1;
  counter-increment: list-1;
}

.ql-editor >>> ol li.ql-indent-1::before {
  content: counter(list-1, lower-alpha) ". ";
}

.ql-editor >>> ol li.ql-indent-2 {
  counter-reset: list-2;
  counter-increment: list-2;
}

.ql-editor >>> ol li.ql-indent-2::before {
  content: counter(list-2, lower-roman) ". ";
}

.ql-editor >>> ol li.ql-indent-3 {
  counter-reset: list-3;
  counter-increment: list-3;
}

.ql-editor >>> ol li.ql-indent-3::before {
  content: counter(list-3, decimal) ". ";
}

.ql-editor >>> ol li.ql-indent-4 {
  counter-reset: list-4;
  counter-increment: list-4;
}

.ql-editor >>> ol li.ql-indent-4::before {
  content: counter(list-4, lower-alpha) ". ";
}

.ql-editor >>> ol li.ql-indent-5 {
  counter-reset: list-5;
  counter-increment: list-5;
}

.ql-editor >>> ol li.ql-indent-5::before {
  content: counter(list-5, lower-roman) ". ";
}

.ql-editor >>> ol li.ql-indent-6 {
  counter-reset: list-6;
  counter-increment: list-6;
}

.ql-editor >>> ol li.ql-indent-6::before {
  content: counter(list-6, decimal) ". ";
}

.ql-editor >>> ol li.ql-indent-7 {
  counter-reset: list-7;
  counter-increment: list-7;
}

.ql-editor >>> ol li.ql-indent-7::before {
  content: counter(list-7, lower-alpha) ". ";
}

.ql-editor >>> ol li.ql-indent-8 {
  counter-reset: list-8;
  counter-increment: list-8;
}

.ql-editor >>> ol li.ql-indent-8::before {
  content: counter(list-8, lower-roman) ". ";
}
</style>